import React, { CSSProperties } from "react";
import { Icon } from "@iconify/react";
import telephoneIcon from "@iconify/icons-bi/telephone";
import hamburgerMenu from "@iconify/icons-cil/hamburger-menu";
import bxlWhatsapp from "@iconify/icons-bx/bxl-whatsapp";
import facebookOutlined from "@iconify/icons-ant-design/facebook-outlined";
import mailOutlined from "@iconify/icons-ant-design/mail-outlined";
import checkIcon from "@iconify/icons-entypo/check";
import locationIcon from "@iconify/icons-bytesize/location";
import houseDoor from "@iconify/icons-bi/house-door";
import dotMark from "@iconify/icons-carbon/dot-mark";

import styled from "styled-components";

interface IconProps {
  color?: "white" | "grey" | "black";
  size?: "medium" | "big";
  onClick?: (e: MouseEvent) => void;
  className?: string;
  style?: CSSProperties;
}

const normalIconSizes = {
  medium: {
    height: 21,
    width: 21,
  },
  big: {
    height: 30,
    width: 30,
  },
};

const biggerIconSizes = {
  medium: {
    height: 30,
    width: 30,
  },
  big: {
    height: 36,
    width: 36,
  },
};

const IconWrapper = styled<any>(Icon)`
  color: ${({ color, theme }) => theme[color] || color};
  height: ${({ dimensions }) => dimensions.height}px;
  width: ${({ dimensions }) => dimensions.width}px;
`;

export const TelephoneIcon: React.FC<IconProps> = props => {
  const sizes = normalIconSizes;

  return (
    <IconWrapper
      dimensions={sizes[props.size || "medium"]}
      {...props}
      icon={telephoneIcon}
    />
  );
};

export const WhatsappIcon: React.FC<IconProps> = props => {
  const sizes = biggerIconSizes;

  return (
    <IconWrapper
      dimensions={sizes[props.size || "medium"]}
      {...props}
      icon={bxlWhatsapp}
    />
  );
};

export const FacebookIcon: React.FC<IconProps> = props => {
  const sizes = biggerIconSizes;

  return (
    <IconWrapper
      dimensions={sizes[props.size || "medium"]}
      {...props}
      icon={facebookOutlined}
    />
  );
};

export const MailIcon: React.FC<IconProps> = props => {
  const sizes = biggerIconSizes;

  return (
    <IconWrapper
      dimensions={sizes[props.size || "medium"]}
      {...props}
      icon={mailOutlined}
    />
  );
};

export const CheckIcon: React.FC<IconProps> = props => {
  const sizes = biggerIconSizes;

  return (
    <IconWrapper
      dimensions={sizes[props.size || "medium"]}
      {...props}
      icon={checkIcon}
    />
  );
};

export const MarkerIcon: React.FC<IconProps> = props => {
  const sizes = biggerIconSizes;

  return (
    <IconWrapper
      dimensions={sizes[props.size || "medium"]}
      {...props}
      icon={locationIcon}
    />
  );
};

export const HouseIcon: React.FC<IconProps> = props => {
  const sizes = normalIconSizes;

  return (
    <IconWrapper
      dimensions={sizes[props.size || "medium"]}
      {...props}
      icon={houseDoor}
    />
  );
};

export const HamburgerIcon: React.FC<IconProps> = props => {
  const sizes = biggerIconSizes;

  return (
    <IconWrapper
      dimensions={sizes[props.size || "medium"]}
      {...props}
      icon={hamburgerMenu}
    />
  );
};

export const DotIcon: React.FC<IconProps> = props => {
  const sizes = biggerIconSizes;

  return (
    <IconWrapper
      dimensions={sizes[props.size || "medium"]}
      {...props}
      icon={dotMark}
    />
  );
};
