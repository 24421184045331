const theme = {
  blue: "#0A2892",
  pink: "#FF5387",
  black: "#252525",
  grey: "#6C6C6C",
  lightGrey: "#F0F0F0",
  white: "#FFFFFF",
  breakPointSm: 576,
  breakPointMd: 768,
  breakPointLg: 992,
  breakPointXl: 1200,
};

export default theme;
