import styled from "styled-components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { NavContext } from "../../contexts/NavContext";
import { Motion, spring } from "react-motion";
import zIndexLayers from "../general/zIndexLayers";

type ClickLayerProps = {
  zIndex: number;
};

const ClickLayer = styled.div<ClickLayerProps>`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex};
`;

type NavBoxProps = {
  topOffset: number;
  width: number;
  zIndex: number;
};

const NavBox = styled.nav<NavBoxProps>`
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.grey};
  width: ${({ width }) => width}px;
  height: calc(100% - 62px);
  position: fixed;
  top: ${({ topOffset }) => topOffset}px;
  z-index: ${({ zIndex }) => zIndex};
  box-shadow: -1px -1px 15px rgba(0, 0, 0, 0.1);
`;

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
}

const MobileNavWrapper: React.FC<Props> = ({ children }) => {
  const { closeNav, isNav } = useContext(NavContext);
  const [zIndex, setZIndex] = useState(-1);

  const timeout = useRef<number>(null);

  // fix zindex bug using useeffect
  useEffect(() => {
    if (isNav) {
      setZIndex(zIndexLayers.nav);

      clearTimeout(timeout.current);
    } else {
      timeout.current = setTimeout(() => {
        setZIndex(-1);
      }, 600);
    }
  }, [isNav]);

  const navWidth = 300;
  const headerHeight = 62;
  const rightOffset = isNav ? -0 : -(navWidth + 30);

  return (
    <Motion style={{ rightOffset: spring(rightOffset) }}>
      {({ rightOffset }) => (
        <>
          <ClickLayer
            id="close-nav"
            zIndex={isNav ? zIndexLayers.navClickLayer : -1}
            onClick={closeNav}
          ></ClickLayer>

          <NavBox
            style={{ right: rightOffset }}
            zIndex={zIndex}
            topOffset={headerHeight}
            width={navWidth}
          >
            {children}
          </NavBox>
        </>
      )}
    </Motion>
  );
};

export default MobileNavWrapper;
