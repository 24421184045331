import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./footer/Footer";
import { ThemeProvider } from "styled-components";
import { NavContextProvider } from "../../contexts/NavContext";
import theme from "./theme";
import MobileNav from "./MobileNav";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/index.scss";

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
  flex-grow: 1;
`;

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <NavContextProvider>
        <LayoutContainer>
          <Header />
          <MobileNav />
          <Main>{children}</Main>
          <Footer />
        </LayoutContainer>
      </NavContextProvider>
    </ThemeProvider>
  );
};

export default Layout;
