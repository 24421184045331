import React, { CSSProperties } from "react";

type StringNumber = string | number;

export interface DivProps {
  m?: StringNumber;
  mt?: StringNumber;
  mb?: StringNumber;
  ml?: StringNumber;
  mr?: StringNumber;
  my?: StringNumber;
  mx?: StringNumber;
  p?: StringNumber;
  pt?: StringNumber;
  pb?: StringNumber;
  pl?: StringNumber;
  pr?: StringNumber;
  py?: StringNumber;
  px?: StringNumber;
  border?: boolean;
  h?: StringNumber;
  w?: StringNumber;
  "text-center"?: boolean;
  "text-left"?: boolean;
  "text-right"?: boolean;
  style?: CSSProperties;
  className?: string;
}

export const createBootstrapClasses = (props: DivProps): string[] => {
  const classes = [];

  Object.keys(props).forEach(prop => {
    const skipProps = ["children", "style", "className"];
    if (skipProps.includes(prop)) return;

    const value = props[prop];

    if (typeof value === "boolean") {
      classes.push(prop);
    } else {
      classes.push(`${prop}-${value}`);
    }
  });

  return classes;
};

const Box: React.FC<DivProps> = props => {
  const classes = createBootstrapClasses(props);

  return (
    <div style={props.style || undefined} className={classes.join(" ")}>
      {props.children}
    </div>
  );
};

export default Box;
