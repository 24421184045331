import React from "react";

interface Props {
  size?: "medium" | "big";
}

const Logo: React.FC<Props> = ({ size = "medium" }) => {
  const widths = {
    medium: 80,
    big: 140,
  };

  return (
    <img
      src="/images/rijschoollamar-logo-transparent.png"
      alt="Rijschool Lamar Logo"
      style={{ width: widths[size] }}
    />
  );
};

export default Logo;
