import React, { CSSProperties } from "react";
import styled, { css } from "styled-components";

type TextProps = {
  upcase?: boolean;
  color?: "blue" | "black" | "grey" | "white";
  fontSize?: "small" | "medium" | "large" | "xl" | "xxl";
  display?: boolean;
  cursive?: boolean;
  bold?: boolean;
  light?: boolean;
  background?: "white";
  className?: string;
  style?: CSSProperties;
  id?: string;
};

const fontSizes = {
  small: 1,
  medium: 1.2,
  large: 1.5,
  xl: 2,
  xxl: 2.2,
};

const HeadingStyles = css<TextProps>`
  font-weight: ${({ bold, light }) => (bold ? "bold" : light ? 200 : "bold")}; ;
`;

const TextStyles = css<TextProps>`
  text-transform: ${({ upcase }) => (upcase ? "uppercase" : "none")};
  color: ${({ color, theme }) => theme[color || "black"]};
  font-style: ${({ cursive }) => (cursive ? "italic" : "normal")};
  font-weight: ${({ bold, light }) => (bold ? "bold" : light ? 200 : "normal")};
  ${({ display }) => (display ? "font-size: 50px" : "")};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSizes[fontSize]}em;` : "")}
`;

const StyledH1 = styled.h1<TextProps>`
  ${TextStyles}
  ${({ bold, light }) => (!bold && !light ? HeadingStyles : null)}
`;

const StyledH2 = styled.h2<TextProps>`
  ${TextStyles}
  ${HeadingStyles}
`;

const StyledH3 = styled.h3<TextProps>`
  ${TextStyles}
  ${HeadingStyles}
`;

const StyledP = styled.p<TextProps>`
  ${TextStyles}
`;

const StyledSpan = styled.span<TextProps>`
  ${TextStyles}
`;

export const H1: React.FC<TextProps> = ({ children, ...props }) => {
  return (
    <StyledH1
      className={props.background === "white" ? "text--bg-white" : ""}
      {...props}
    >
      {children}
    </StyledH1>
  );
};

export const H2: React.FC<TextProps> = ({ children, ...props }) => {
  return <StyledH2 {...props}>{children}</StyledH2>;
};

export const H3: React.FC<TextProps> = ({ children, ...props }) => {
  return <StyledH3 {...props}>{children}</StyledH3>;
};

export const P: React.FC<TextProps> = ({ children, ...props }) => {
  return <StyledP {...props}>{children}</StyledP>;
};

export const Span: React.FC<TextProps> = ({ children, ...props }) => {
  return <StyledSpan {...props}>{children}</StyledSpan>;
};
