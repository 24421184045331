import { Link } from "gatsby";
import React from "react";
import Box from "../../general/Box";
import Flex from "../../general/Flex";
import { H3, P } from "../../general/Typography";

interface Props { }

const Instructeurs: React.FC<Props> = () => {
  return (
    <Box text-left>
      <Flex justify-content="center" justify-content-md="start" mb="3">
        <Link to="/instructeurs">
          <H3 light fontSize="small" upcase>
            Instructeurs
          </H3>
        </Link>
      </Flex>

      <Flex justify-content="between" px="5" px-md="0">
        <div>
          <P bold>Amar</P>
          <a href="tel:0647079774">
            <P light>0647079774</P>
          </a>
        </div>

        <div>
          <P bold>Rima</P>
          <a href="tel:0613501311">
            <P light>0613501311</P>
          </a>
        </div>
      </Flex>
    </Box>
  );
};

export default Instructeurs;
