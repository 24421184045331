import React from "react";
import { DivProps, createBootstrapClasses } from "./Box";

type PositionType =
  | "start"
  | "center"
  | "between"
  | "evenly"
  | "around"
  | "end";

type FlexProps = DivProps & {
  "justify-content"?: PositionType;
  "align-items"?: PositionType;
  "flex-column"?: boolean;
};

const Flex: React.FC<FlexProps> = props => {
  const classes = createBootstrapClasses(props);

  return (
    <div
      style={props.style || undefined}
      className={`d-flex ${classes.join(" ")}`}
    >
      {props.children}
    </div>
  );
};

export default Flex;
