import { Link } from "gatsby";
import { P } from "../../general/Typography";
import React from "react";
import Box from "../../general/Box";
import Logo from "../../general/images/Logo";
import Button from "../../general/Button";

interface Props {}

const FooterMenu: React.FC<Props> = () => {
  return (
    <Box text-md-left>
      <Box py={4}>
        <Logo size="big" />
      </Box>

      <Box>
        <Link to="/">
          <P light upcase>
            Home
          </P>
        </Link>

        <Link to="/pakketten">
          <P light upcase>
            Auto pakketten
          </P>
        </Link>

        <Link to="/motorpakketten">
          <P light upcase>
            Motor pakketten
          </P>
        </Link>

        <Link to="/instructeurs">
          <P light upcase>
            Instructeurs
          </P>
        </Link>

        <Link to="/contact">
          <P light upcase>
            Contact
          </P>
        </Link>
      </Box>

      <Box my="4">
        <Button to="/contact?message=Hoi Amar, <br><br>Ik wil graag een afspraak maken!">
          Maak nu een afspraak!
        </Button>
      </Box>
    </Box>
  );
};

export default FooterMenu;
