import React from "react";
import { P } from "./Typography";
import {
  TelephoneIcon,
  WhatsappIcon,
  FacebookIcon,
  MailIcon,
  HouseIcon,
  MarkerIcon,
} from "./Icon";
import Box from "../general/Box";
import Flex from "../general/Flex";

interface Props {
  color?: "black" | "grey";
  variant?: "withAddress" | "short" | "long";
}

const ContactList: React.FC<Props> = ({
  color = "black",
  variant = "short",
}) => {
  const isLongVariant = variant === "long";

  return (
    <Box text-left>
      {variant === "withAddress" ? (
        <Flex align-items="center" mb="3">
          <HouseIcon className="mr-4" color={color} size="big" />
          <Box>
            <P light className="mb-2" color={color}>
              Mathenessestraat 59
            </P>
            <P light className="mb-0" color={color}>
              2361 KB Warmond
            </P>
          </Box>
        </Flex>
      ) : (
        <Flex align-items="center" mb="3">
        <MarkerIcon className="mr-4" color={color} size="big" style={{minWidth: 30}} />
        <Box>
          <P light className="mb-0" color={color}>
            Leiden | Warmond | Katwijk | Wassenaar | Noordwijk | Voorschoten | Oegstgeest | Noordwijkerhout | Voorhout
          </P>
        </Box>
      </Flex>

      )}
      <a href="tel:0647079774">
        <Flex align-items="center" mb="3">
          <TelephoneIcon className="mr-4" color={color} size="big" />
          <Box>
            <P light className="mb-2" color={color}>
              Bel ons
            </P>
            <P light className="mb-0" color={color}>
              0647079774
            </P>
          </Box>
        </Flex>
      </a>

      <a href="https://wa.me/31647079774">
        <Flex align-items="center" mb="3">
          <WhatsappIcon className="mr-3" color={color} size="big" />
          <P light className="mb-0" color={color}>
            {isLongVariant ? "Neem contact op via Whatsapp" : "Whatsapp"}
          </P>
        </Flex>
      </a>

      <a href="https://www.facebook.com/RijschoolLamar">
        <Flex align-items="center" mb="3">
          <FacebookIcon className="mr-3" color={color} size="big" />
          <P light className="mb-0" color={color}>
            {isLongVariant ? "Check onze Facebook Page" : "Facebook"}
          </P>
        </Flex>
      </a>

      <a href="mailto:info@rijschoollamar.nl">
        <Flex align-items="center" mb="3">
          <MailIcon className="mr-3" color={color} size="big" />
          <P light className="mb-0" color={color}>
            Stuur ons een mail
          </P>
        </Flex>
      </a>
    </Box>
  );
};

export default ContactList;
