import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "reactstrap";
import { H3, P, Span } from "../../general/Typography";
import Instructeurs from "./Instructeurs";
import ContactList from "../../general/ContactList";
import Box from "../../general/Box";
import Flex from "../../general/Flex";
import FooterMenu from "./FooterMenu";
import useViewPort from "../../../hooks/useViewPort";

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.lightGrey};
`;

interface Props {}

const Footer: React.FC<Props> = () => {
  const { breakpoint } = useViewPort();

  return (
    <StyledFooter className="text-center">
      <Container>
        <Row>
          <Col xs="12" md="6" lg="4" className="mb-md-5">
            <FooterMenu />
          </Col>

          <Col xs="12" md="6" lg="4" className="mb-md-5">
            <Box my={5}>
              <Instructeurs />
            </Box>
          </Col>

          <Col xs="12" md="6" lg={{ size: 3, offset: 1 }}>
            <Box text-md-left mt-lg="5">
              <H3 upcase light fontSize="small">
                Contact
              </H3>

              <Flex
                mt="5"
                mt-lg="4"
                mb="5"
                mb-md="0"
                justify-content="center"
                justify-content-md="start"
              >
                <ContactList
                  variant={
                    ["lg", "xl"].includes(breakpoint) ? "long" : undefined
                  }
                />
              </Flex>
            </Box>
          </Col>

          <Col xs="12" md="6" lg="12" className="mt-lg-4">
            {/* Until lg */}
            <Flex
              flex-column
              mt-md="5"
              mb="5"
              mb-md="0"
              text-md-right
              justify-content-md="end"
              d-lg-none
            >
              <P bold>
                KVK: <Span light>51461536</Span>
              </P>
              <P bold>
                CBR NR: <Span light>3084k5</Span>
              </P>

              <P bold className="mt-5">
                ©{new Date().getFullYear()} <Span light>Rijschool Lamar</Span>
              </P>

              <P light className="mt-5">
                Website gemaakt door:{" "}
                <a href="https://ticekralt.com">
                  <Span bold>Tice Kralt</Span>
                </a>
              </P>
            </Flex>

            {/* Lg and larger */}
            <Box d-none d-lg-flex justify-content="between">
              <P bold>
                ©{new Date().getFullYear()} <Span light>Rijschool Lamar</Span>
              </P>

              <Flex justify-content="center">
                <P bold className="mr-4">
                  KVK: <Span light>51461536</Span>
                </P>
                <P bold>
                  CBR NR: <Span light>3084k5</Span>
                </P>
              </Flex>

              <P light>
                Website gemaakt door:{" "}
                <a href="https://ticekralt.com">
                  <Span bold>Tice Kralt</Span>
                </a>
              </P>
            </Box>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
