import { Link } from "gatsby";
import React from "react";
import { TelephoneIcon } from "../general/Icon";
import { P } from "../general/Typography";

interface Props {}

const DesktopNav: React.FC<Props> = () => {
  return (
    <>
      <Link to="/">
        <P className="m-0" color="grey" upcase>
          Home
        </P>
      </Link>

      <Link to="/pakketten">
        <P className="m-0" color="grey" upcase>
          Auto pakketten
        </P>
      </Link>
      <Link to="/motorpakketten">
        <P className="m-0" color="grey" upcase>
          Motor pakketten
        </P>
      </Link>
      <Link to="/instructeurs">
        <P className="m-0" color="grey" upcase>
          Instructeurs
        </P>
      </Link>
      <Link to="/contact">
        <P className="m-0" color="grey" upcase>
          Contact
        </P>
      </Link>
      <a href="tel:31647079774">
        <P className="m-0 mr-5" color="grey" upcase>
          <TelephoneIcon className="mr-2" /> 0647079774
        </P>
      </a>
    </>
  );
};

export default DesktopNav;
