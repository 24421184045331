import React, { useContext } from "react";
import { NavContext } from "../../contexts/NavContext";
import styled, { useTheme } from "styled-components";
import { HamburgerIcon, TelephoneIcon } from "../general/Icon";
import Logo from "../general/images/Logo";
import { Link } from "gatsby";
import zIndexLayers from "../general/zIndexLayers";
import useViewPort from "../../hooks/useViewPort";
import DesktopNav from "./DesktopNav";
import Flex from "../general/Flex";
import Box from "../general/Box";

const StyledHeader = styled.header<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: ${({ height }) => height}px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  z-index: ${zIndexLayers.header};
`;

const LogoSection = styled.div`
  width: 150px;
  @media (min-width: ${({ theme }) => theme.breakPointLg}px) {
    width: 300px;
  }

  @media (min-width: ${({ theme }) => theme.breakPointXl}px) {
    width: 500px;
  }
`;

const Header: React.FC<{}> = () => {
  const { breakPointMd } = useTheme() as any;
  const { width } = useViewPort();

  const { openNav, headerHeight } = useContext(NavContext);

  return (
    <>
      <StyledHeader height={headerHeight}>
        <LogoSection>
          <Link to="/">
            <Logo />
          </Link>
        </LogoSection>
        <Flex align-items="center" flex-grow-1 justify-content="between">
          {width > breakPointMd ? <DesktopNav /> : null}
          {width <= breakPointMd ? (
            <>
              <div></div>
              <Flex justify-self="end">
                <Box mr="4">
                  <a href="tel:0647079774">
                    <Box>
                      <TelephoneIcon color="black" />
                    </Box>
                  </a>
                </Box>
                <HamburgerIcon
                  style={{ cursor: "pointer" }}
                  onClick={openNav}
                />
              </Flex>
            </>
          ) : null}
        </Flex>
      </StyledHeader>
      <div style={{ height: headerHeight }}></div>
    </>
  );
};

export default Header;
