import { Link } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";

const ButtonStyles = css`
  padding: 7px 15px;
  border: none;
  border-radius: 11px;
  background-color: ${({ theme }) => theme.pink};
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2em;
  transition: transform 100ms ease-out;
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${({ theme }) => theme.white};
  }
  &:active {
    transform: scale(0.95);
  }
`;

const StyledButton = styled.button`
  ${ButtonStyles}
`;

const StyledButtonLink = styled(Link)`
  ${ButtonStyles}
`;

interface Props {
  to?: string;
  onClick?: () => void;
  type?: "submit";
}

const Button: React.FC<Props> = ({ to, onClick, type, children }) => {
  if (to) return <StyledButtonLink to={to}>{children}</StyledButtonLink>;

  return (
    <StyledButton onClick={onClick} type={type}>
      {children}
    </StyledButton>
  );
};

export default Button;
