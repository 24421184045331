import React from "react";
import { P } from "../general/Typography";
import MobileNavWrapper from "./MobileNavWrapper";
import { Link } from "gatsby";
import ContactList from "../general/ContactList";
import Box from "../general/Box";
import Flex from "../general/Flex";

interface Props {}

const MobileNavList: React.FC<Props> = () => {
  return (
    <MobileNavWrapper>
      <Flex px="4" py="4" py-md="5" flex-column h="100">
        <Box flex-grow="1">
          <Link to="/">
            <P upcase color="grey">
              Home
            </P>
          </Link>
          <Link to="/pakketten">
            <P upcase color="grey">
              Auto pakketten
            </P>
          </Link>
          <Link to="/motorpakketten">
            <P upcase color="grey">
              Motor pakketten
            </P>
          </Link>
          <Link to="/instructeurs">
            <P upcase color="grey">
              Instructeurs
            </P>
          </Link>
          <Link to="/contact">
            <P upcase color="grey">
              Contact
            </P>
          </Link>
        </Box>
        <ContactList color="grey" />
      </Flex>
    </MobileNavWrapper>
  );
};

export default MobileNavList;
